import { i18n } from '@lingui/core';
import { detect, fromNavigator, fromStorage } from '@lingui/detect-locale';

export const locales = {
  en: 'English',
  it: 'Italiano',
  es: 'Español',
};

export async function activateLocale(locale) {
  locale = locale || detect(fromStorage('lang'), fromNavigator(), 'en');
  if (!locales[locale]) locale = 'en';
  const { messages } = await import(`./locales/${locale}`);
  i18n.load(locale, messages);
  i18n.activate(locale);
  if (typeof window !== 'undefined' && window.localStorage?.setItem != null) {
    window.localStorage.setItem('lang', locale);
  }
}
