import { usePopup } from 'api/header/usePopup';
import { getPopupImageUrl } from 'helpers/imageUrl';
import { AddPopup } from './AddPopup';
import { useLingui } from '@lingui/react/macro';
import { useState } from 'react';

const localStorageItem = 'hidePopup';
export const Add = () => {
  const { t } = useLingui();
  const hidePopup = window.sessionStorage.getItem(localStorageItem);
  const [isClosed, setIsClosed] = useState(false);
  const { data, isValidating } = usePopup(!!hidePopup || isClosed);

  const onCloseHandler = () => {
    setIsClosed(true);
    window.sessionStorage.setItem(localStorageItem, 'true');
  };

  return (
    data &&
    !isValidating &&
    !hidePopup &&
    !isClosed && (
      <AddPopup
        isOpen={true}
        onClose={onCloseHandler}
        bgColor={data?.bgcolour}
        textColor={data?.colour}
        imgUrl={data?.uuid && getPopupImageUrl(data.uuid)}
        title={t`Click anywhere to close`}
      />
    )
  );
};
