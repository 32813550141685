import { post } from '../base';

export const useUserAuth = () => ({
  activate: (data) => post('/activate', data),

  register: (data) => post('/register', data),

  login: (data) => post('/login', data),

  logout: () => post('/logout'),
});
