import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLingui } from '@lingui/react/macro';
import { useModifyCurrency } from 'api/header/useModifyCurrency';
import { getAsyncCookie } from 'helpers/cookies';
import { useNotifications } from 'components/common/Notifications';
import { useCurrencies } from '../api/currency/useCurrencies';

export const CurrencyContext = createContext();

export function CurrencyProvider({ children }) {
  const { t } = useLingui();
  const [currency, setCurrency] = useState();
  const [currencies, setCurrencies] = useState();
  const { addNotification } = useNotifications();
  const modifyCurrency = useModifyCurrency();
  const { getCurrencies } = useCurrencies();

  const onProviderInit = useCallback(async () => {
    try {
      if (!currencies) {
        const { currencies } = await getCurrencies();
        setCurrencies(currencies);
      } else {
        const currencyFromCookie = await getAsyncCookie(
          'currency',
          Object.keys(currencies)
        );
        setCurrency(currencyFromCookie);
      }
    } catch (error) {
      addNotification(
        t`Something went wrong! Please refresh the page.`,
        'danger',
        true
      );
    }
  }, [addNotification, currencies, getCurrencies, t]);

  const handleSetCurrency = useCallback(
    async (newCurrency) => {
      try {
        await modifyCurrency(newCurrency);
        setCurrency(newCurrency);
      } catch (error) {
        console.error(error);
      }
    },
    [modifyCurrency]
  );

  const ctx = useMemo(
    () => ({
      code: currency,
      symbol: (currencies && currencies[currency]?.sym) || '',
      right: (currencies && currencies[currency]?.right) || 0,
      deliveryFrom:
        (currencies && currencies[currency]?.deliveryFrom) || '3.50',
      setCurrency: handleSetCurrency,
      defaultPrices: (currencies && currencies[currency]?.defaultPrices) || {},
    }),
    [currency, handleSetCurrency, currencies]
  );

  useEffect(() => {
    onProviderInit();
  }, [onProviderInit]);

  return currencies ? (
    <CurrencyContext.Provider value={ctx}>{children}</CurrencyContext.Provider>
  ) : null;
}
