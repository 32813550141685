import { Trans } from '@lingui/react/macro';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import NewsletterForm from '../NewsletterForm';
import routeNames from 'router/routeNames.json';
import WhatIsQwerteeModal from 'components/help/WhatIsQwerteeModal';

import './Footer.css';

const linkClasses = 'hover:underline';
const socialIconClasses =
  'h-16 w-16 mr-2 bg-cover bg-no-repeat text-transparent';
const headerClasses =
  'text-sm xs:text-base sm:text-lg uppercase mb-3 font-bold font-sans';

export default function Footer() {
  return (
    <>
      <div id="footer">
        <div className="page-inner-width container-md">
          <div className="footer-top px-0 mx-0 flex flex-col md:flex-row">
            <div className="flex-1 pr-2">
              <h4>
                <Trans>Newsletter:</Trans>
              </h4>
              <p>
                <Trans>
                  Get a €2/£2/$2 discount code, sneak peeks of upcoming
                  tees/sales, a chance at a free tee every week and more!
                </Trans>
              </p>
              <NewsletterForm />
            </div>
            <div className="flex-1 px-2">
              <h4>
                <Trans>WANT TO WIN FREE TEES?</Trans>
              </h4>
              <Trans>
                We give away over 30 FREE TEES every single week.
                <br />
                Just <Link to={routeNames.freeTees}>click here</Link> to win
                some now!!!
              </Trans>
            </div>
            <div id="social-connect" className="flex-1 pl-2">
              <h4>
                <Trans>Connect with Qwertee (to win!):</Trans>
              </h4>
              <div className="flex justify-content-start">
                <a
                  className={classNames(
                    socialIconClasses,
                    'bg-facebook-footer hover:bg-facebook-footer-hover'
                  )}
                  href="https://www.facebook.com/Qwertee"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share with Facebook
                </a>
                <a
                  className={classNames(
                    socialIconClasses,
                    'bg-tweeter-footer hover:bg-tweeter-footer-hover'
                  )}
                  href="https://twitter.com/qwertee"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share with Tweeter
                </a>
              </div>
            </div>
          </div>

          <div className="clear" />
          <hr />
          <div className="flex px-0 mx-0">
            <div className="w-40 mx-1">
              <h4 className={headerClasses}>
                <Trans>Tees:</Trans>
              </h4>
              <ol>
                <li>
                  <Link to={routeNames.home} className={linkClasses}>
                    <Trans>Today's Tee</Trans>
                  </Link>
                </li>
                <li>
                  <Link to={routeNames.lastChance} className={linkClasses}>
                    <Trans>Last Chance Tees</Trans>
                  </Link>
                </li>
                <li>
                  <Link to={routeNames.shopTees} className={linkClasses}>
                    <Trans>Shop Tees</Trans>
                  </Link>
                </li>
                <li>
                  <Link to={routeNames.freeTees} className={linkClasses}>
                    <Trans>Win FREE TEES!</Trans>
                  </Link>
                </li>
              </ol>
            </div>
            <div className="w-40 mx-1">
              <h4 className={headerClasses}>
                <Trans>Vote:</Trans>
              </h4>
              <ol>
                <li>
                  <Link to={routeNames.vote} className={linkClasses}>
                    <Trans>Vote For Tees</Trans>
                  </Link>
                </li>
                <li>
                  <a
                    href={`${routeNames.teesVotesNewest}`}
                    className={linkClasses}
                  >
                    <Trans>Newest Tees</Trans>
                  </a>
                </li>
                <li>
                  <a
                    href={`${routeNames.teesVotesPopular}`}
                    className={linkClasses}
                  >
                    <Trans>Most Voted For Tees</Trans>
                  </a>
                </li>
                <li>
                  <Link to={routeNames.designsSubmit} className={linkClasses}>
                    <Trans>Submit Your Design</Trans>
                  </Link>
                </li>
              </ol>
            </div>
            <div className="w-40 mx-1">
              <h4 className={headerClasses}>
                <Trans>Talk:</Trans>
              </h4>
              <ol>
                <li>
                  <a href={`${routeNames.rss}`} className={linkClasses}>
                    <Trans>RSS Feed</Trans>
                  </a>
                </li>
              </ol>
            </div>
            <div className="w-40 mx-1">
              <h4 className={headerClasses}>
                <Trans>Help:</Trans>
              </h4>
              <ol>
                <li>
                  <WhatIsQwerteeModal
                    linkClassName={classNames(
                      'text-white cursor-pointer font-bold',
                      linkClasses
                    )}
                  >
                    <Trans>How Does Qwertee Work?</Trans>
                  </WhatIsQwerteeModal>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_SERVER_SITE}${routeNames.help}`}
                    className={linkClasses}
                  >
                    <Trans>Help &amp; FAQ</Trans>
                  </a>
                </li>
                <li>
                  <Link to={routeNames.userOrders} className={linkClasses}>
                    <Trans>Order Tracking</Trans>
                  </Link>
                </li>
                <li>
                  <a href={routeNames.helpContact} className={linkClasses}>
                    <Trans>Contact Us</Trans>
                  </a>
                </li>
                <li>
                  <a href={`${routeNames.jobs}`} className={linkClasses}>
                    <Trans>Jobs</Trans>
                  </a>
                </li>
              </ol>
            </div>
            <div className="w-44 mx-1">
              <h4 className={headerClasses}>
                <Trans>Company Info:</Trans>
              </h4>
              <p className="text-xs">
                <strong>
                  <Trans>Qwertee Registered Address:</Trans>
                </strong>
                <br />
                Qwertee
                <br />
                Block 3<br />
                Harcourt Road
                <br />
                Dublin 2<br />
                Ireland
                <br />
                Company Number: 476522
                <br />
              </p>
            </div>
          </div>
          <div className="clear" />
          <hr />
          <div id="footer-copyright">
            <Trans>
              &copy; Qwertee {new Date().getFullYear()}. All designs copyright
              by owner.
            </Trans>
            <a href={`${routeNames.privacy}`} className={linkClasses}>
              <Trans>Privacy Policy</Trans>
            </a>
            .{' '}
            <a href={`${routeNames.terms}`} className={linkClasses}>
              <Trans>Terms of Use</Trans>
            </a>
            .{' '}
            <a href={`${routeNames.cookies}`} className={linkClasses}>
              <Trans>Cookies Policy</Trans>
            </a>
            .{' '}
            <a href={routeNames.copyright} className={linkClasses}>
              <Trans>Copyright Complaint</Trans>
            </a>
            .{' '}
          </div>
        </div>
      </div>
    </>
  );
}
