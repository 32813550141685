import { Trans } from '@lingui/react/macro';
import { Link } from 'react-router-dom';
import { useCartCount } from 'api/header/useCartCount';
import routeNames from 'router/routeNames.json';

export function YourCartLink({ className }) {
  const { data } = useCartCount();

  if (!data) {
    return null;
  }
  const { count } = data;
  return (
    <Link
      to={`${routeNames.checkout}${routeNames.checkoutForm}`}
      className={className}
    >
      <Trans>Your cart ({count})</Trans>
    </Link>
  );
}
