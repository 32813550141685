import { useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { useLingui } from '@lingui/react/macro';

export const AddPopup = ({
  isOpen,
  onClose,
  bgColor,
  textColor,
  imgUrl,
  title,
}) => {
  const { t } = useLingui();
  const dialogPanelRef = useRef();

  return (
    <Dialog
      className="fixed inset-0 flex pt-[10vh] justify-center bg-gray-900/70 z-10"
      as="div"
      open={isOpen}
      onClose={onClose}
      initialFocus={dialogPanelRef}
    >
      <Dialog.Panel ref={dialogPanelRef} onClick={onClose}>
        <Dialog.Title
          className="font-semibold text-center text-sm md:text-base leading-4 md:leading-5 hover:underline cursor-pointer"
          style={{
            backgroundColor: `#${bgColor || '333'}`,
            color: `#${textColor || 'EEE'}`,
          }}
        >
          {title}
        </Dialog.Title>
        <img src={imgUrl} alt={t`Add popup`} />
      </Dialog.Panel>
    </Dialog>
  );
};
