import { Trans } from '@lingui/react/macro';
import { Button } from 'components/common/Button';
import { Dialog } from '@headlessui/react';
import { forwardRef, useRef } from 'react';

const buttonClassNames =
  'text-sm xs:text-base md:!text-xl !uppercase !py-0.5 !px-5 !rounded-xl font-sans m-0.5 w-fit';

export const Modal = forwardRef(({ id, isOpen = true, onClose, title = <Trans>
      THANKS
    </Trans>, children, showCloseButton = true }, ref) => {
  const closeButtonRef = useRef();

  return (
    <Dialog
      as="div"
      className="fixed inset-0 overflow-y-auto flex justify-center z-20"
      id={id}
      open={isOpen}
      onClose={onClose}
      initialFocus={ref || closeButtonRef}
    >
      <div className="absolute inset-0 bg-black/90" aria-hidden="true" />

      <Dialog.Panel className="bg-white max-w-[52rem] w-full relative top-[10%] h-fit">
          {title ? <Dialog.Title className="bg-qw-blue-500 h-16 text-white text-xl xs:text-2xl md:text-3xl font-bold flex items-center justify-center font-sans">
          {title}
        </Dialog.Title>
        : "" }
        <div className="flex flex-col items-center justify-center ">
          <div className="p-2 flex-1 mx-4 mt-1">{children}</div>

          {showCloseButton && (
            <div className="pt-3 pb-6 text-center">
              <Button
                className={buttonClassNames}
                ref={closeButtonRef}
                size="sm"
                onClick={onClose}
              >
                <Trans>Close</Trans>
              </Button>
            </div>
          )}
        </div>
      </Dialog.Panel>
    </Dialog>
  );
});
