import * as Yup from 'yup';
import { msg } from '@lingui/core/macro';
import { Trans } from '@lingui/react';

const requiredMsg = msg`Required`;
const validMsg = msg`Required`;

export const newsletterFormSchema = Yup.object({
  email: Yup.string()
    .email(<Trans id={validMsg.message} />)
    .required(<Trans id={requiredMsg.message} />),
});
